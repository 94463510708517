// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Rails from "@rails/ujs"
import * as Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// import "@hotwired/turbo-rails"
import * as UserPortal from "../user_portal/index"
import * as bootstrap from 'bootstrap'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// publish UserPortal
window[UserPortal.NAME] = UserPortal;
window['BS'] = bootstrap;
